/*
 * Avoid using this as much as possible. Just directly use the hook.
 * Only using it for other js files that aren't react components
 */
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { autorun } from 'mobx'
import { useNotification } from '@fortressiq/fiq-ds'

import store from './flashStore.js'

export const FLASH_LEVELS = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
}

export const sidebarWidthPlusSpacer = 60

const onCloseHandler = (key, onClose) => {
  if (onClose) {
    onClose()
  }

  store.remove(key)
}

const Flash = () => {
  const { addNotification, removeNotification, updateNotification } = useNotification()

  useEffect(
    () =>
      autorun(() => {
        store.messages.forEach(
          ({ message, description, displayed, remove, cta, key, level, onClose, ...notificationProps }) => {
            if (!displayed) {
              const nProps = {
                align: { left: sidebarWidthPlusSpacer },
                cta,
                description,
                id: key,
                key: key,
                message,
                onClose: () => onCloseHandler(key, onClose),
                type: level,
                ...notificationProps,
                position: 'bottom-left',
              }

              if (store.messages.filter(m => m.id === key).length > 1) {
                updateNotification(key, nProps)
              } else {
                addNotification(nProps)
              }

              store.messageDisplayed(key)
            } else if (remove) {
              removeNotification(key)
              store.remove(key)
            }
          }
        )
      }),
    [addNotification, removeNotification, updateNotification]
  )

  return null
}

const send = ({ message, description, cta }, level, options = {}) =>
  store.add({ message, description, cta }, level, options)
Flash.error = ({ message, description, cta }) => send({ message, description, cta }, FLASH_LEVELS.ERROR)
Flash.info = ({ message, description, cta }, options) => send({ message, description, cta }, FLASH_LEVELS.INFO, options)
Flash.success = ({ message, description, cta }, options) =>
  send({ message, description, cta }, FLASH_LEVELS.SUCCESS, options)
Flash.warning = ({ message, description, cta }, options) =>
  send({ message, description, cta }, FLASH_LEVELS.WARNING, options)
Flash.remove = key => {
  store.close(key)
}

export default observer(Flash)
