import { observable, action } from 'mobx'
import shortid from 'shortid'

class FlashStore {
  @observable messages = []

  @action
  add({ message, description, cta }, level, options = {}) {
    const newMessage = {
      key: options.key || shortid.generate(),
      message,
      description,
      cta,
      level,
      ...options,
    }
    this.messages.push(newMessage)
    return newMessage
  }

  @action
  remove(key) {
    this.messages = this.messages.filter(msg => msg.key !== key)
  }

  @action
  messageDisplayed(key) {
    this.messages = this.messages.map(msg => {
      if (msg.key === key) {
        return { ...msg, displayed: true }
      }
      return msg
    })
  }

  @action
  close(key) {
    let changed = false

    const messages = this.messages.map(msg => {
      if (msg.key === key) {
        if (!msg.remove) {
          changed = true
          return { ...msg, remove: true }
        }
      }
      return msg
    })

    if (changed) {
      this.messages = messages
    }
  }
}

export default new FlashStore()
