import React from 'react'
import classNames from 'classnames'
import { Element } from '@fortressiq/fiq-ds'

import css from './mainLoader.scss'

const MainLoader = ({ className, style }) => (
  <Element className={classNames(css.loaderLayout, className)} style={style}>
    <div className={css.loaderContainer}>
      <div className={css.lowerLeft} />
      <div className={css.lowerRight} />
      <div className={css.topRight} />
      <div className={css.topLeft} />
    </div>
  </Element>
)

export default MainLoader
